import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import HireStar from "../../assets/Videos/HirestarVideo.mp4";
// https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets/Media/HirestarDisplayVideo.mp4
const ButtonComponent = ({ arialLabel }) => {
  const navigate = useNavigate();

  const [activeButton, setActiveButton] = useState(1); // State for button active status
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="mt-4 mb-8  flex mx-auto flex-col md:flex-row justify-center lg:justify-start items-center space-y-4 md:space-y-0 md:space-x-4">
      {/* First Button */}
      <div className="About_btn">
        <button
          onClick={() => navigate("/connect-with-sales")}
          onMouseEnter={() => setActiveButton(1)} // Activate this button on hover
          onMouseLeave={() => setActiveButton(1)} // Keep this button active
          //     className={` rounded-full   px-4   py-2
          //    ${activeButton === 1 ? 'bg-gradient-to-t from-[#0D6EB5] to-[#2AABE2] text-white' : 'bg-transparent text-[#137BBF]'}
          //     `}
          className={`rounded-full px-4 py-2 home_button `}
          aria-label={arialLabel}
          style={{
            background:
              activeButton === 1
                ? "linear-gradient(to top, #0D6EB5, #2AABE2)"
                : "transparent",
            color: activeButton === 1 ? "white" : "#137BBF", // Set #137BBF when not active
          }}
        >
          Contact Sales
        </button>
      </div>

      {/* Second Button */}
      <div>
        <div className="About_btn  " aria-label="">
          <button
            onMouseEnter={() => setActiveButton(2)} // Activate this button on hover
            onMouseLeave={() => setActiveButton(1)} // Reset to first button active
            onClick={handleOpenModal} // Open the modal
            className={`rounded-full p-2 flex items-center justify-center border home_button`}
            style={{
              background:
                activeButton === 2
                  ? "linear-gradient(to top, #0D6EB5, #2AABE2)"
                  : "transparent",
              color: activeButton === 2 ? "white" : "#137BBF", // Set #137BBF when not active
            }}
          >
            <span className="mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z"
                />
              </svg>
            </span>
            Watch Video
          </button>
        </div>

        {/* Modal for video playback */}
        {isModalOpen && (
          <div className="modal-overlay inset-0 z-50 ">
            <div className="modal-content  z-50">
              <video width="800" height="450" controls autoPlay>
                <source src={HireStar} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <button className="close-button" onClick={handleCloseModal}>
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {" "}
                  {/* Increased SVG size */}
                  <rect
                    x="0.4"
                    y="0.4"
                    width="19.2"
                    height="19.2"
                    rx="9.6"
                    fill="white"
                    stroke="url(#paint0_linear_6217_10230)"
                    strokeWidth="0.8"
                  />
                  <path
                    d="M13.0938 7L7 13.0938M7 7L13.0938 13.0938"
                    stroke="#37474F"
                    strokeWidth="1.40625"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_6217_10230"
                      x1="4.74427e-07"
                      y1="-2.5"
                      x2="21.321"
                      y2="20.8527"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2AABE2" />
                      <stop offset="1" stopColor="#0D6EB5" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
        )}

        {/* Add some CSS styles for the modal */}
        <style jsx="true">{`
          .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            background-color: rgba(0, 0, 0, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .modal-content {
            position: relative; /* Position relative for absolute positioning of close button */
            background: white;
            padding: 20px;
            border-radius: 5px;
            text-align: center;
            width: 90%; /* Set width to 90% of the viewport */
            max-width: 800px; /* Maximum width for larger screens */
            height: auto; /* Height will adjust based on content */
          }
          .close-button {
            position: absolute;
            bottom: -30px; /* Adjusted to position the button at the bottom */
            left: 50%; /* Center the button horizontally */
            transform: translateX(-50%); /* Adjust for button width */
            background: transparent; /* Set background to transparent */
            border: none; /* Remove border */
            cursor: pointer;
            padding: 10px; /* Increased padding for a larger clickable area */
          }
        `}</style>
      </div>
    </div>
  );
};

export default ButtonComponent;
