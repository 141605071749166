import { useNavigate } from "react-router-dom";
import LazyImage from "../../../LazyImg/LazyImage";
const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const About_Image = `${digitalOceanURL}/about.png`;

const AboutSection = () => {
  const navigate = useNavigate();

  const handleReadMoreClick = () => {
    navigate("/about-us");
  };
  // console.log("about section");
  return (
    <div className=" sm:mt-4 ">
      <div className="grid lg:grid-cols-2 lg:mx-12 sm:grid-cols-1 sm:gap-8 About-box items-center justify-center mx-2">
        <div className="about-text-box  lg:p-6  text-center lg:text-left">
          <div>
            <button className="rounded-full text-black p-1 h-[37px] w-32 mb-2 mt-2 About-btn font-[16px]">
              ABOUT US{" "}
            </button>
          </div>
          <div className="mt-2">
            {/* <h2 className="font-semibold text-[32px] md:text-[40px] lg:text-[48px] About-Heading">
              Hirestar.io: <br />Leading Transparent, Tamper-Proof, Fast Background Checks
            </h2> */}
            {/* updated heading */}
            <h2 className="font-semibold inline text-[32px] md:text-[40px] lg:text-[48px] About-Heading">
              Hirestar.io: Leading Transparent, Tamper-Proof, Fast Background
              Checks
            </h2>
            {/* --------------------------------- */}
            {/* <h2 className=" font-semibold text-[32px] md:text-[40px] lg:text-[48px] About-Heading">
              Fast Background Checks
            </h2> */}
          </div>
          <div className="mt-4">
            <p className="font-[poppins] font-[16px] ">
              Hirestar.io is a background verification company powered by
              blockchain. Blockchain technology enables us to reduce turnaround
              times, ensure complete transparency, and provide tamper-proof
              reports, making us a leader in this industry ( background
              verification companies, background verification vendors, and
              background checks companies )
            </p>
          </div>
          <div className="mt-4">
            <button
              className="rounded-full text-white w-[134px] h-[52px] mb-2 mt-2 Read-More-btn font-[16px]"
              onClick={handleReadMoreClick}
            >
              Read More{" "}
            </button>
          </div>
        </div>
        <div className="flex justify-end ">
          <img
            src={About_Image}
            alt="about-us girl about section"
            title="Hirestar-About-Image"
            width="500" // Replace with the actual width of your image
            height="300" // Replace with the actual height of your image
            className="object-contain"
          />
          {/* <LazyImage
            src={About_Image}
            alt="about-us girl about section" title="Hirestar-About-Image"
            // width="500" // Replace with the actual width of your image
            // height="300" // Replace with the actual height of your image
            className="object-contain"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
