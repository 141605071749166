import React from "react";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
// import "./get-started.style.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";
const ContactWithSales = () => {
  // const [formdata, setFormdata] = useState({});
  const navigateToHomePage = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur", // Validates on blur, you can change to "onChange" or "onSubmit"
  });

  const onSubmit = async (data) => {
    console.log(data); // Handle form submission here
    axios
      .post(`${process.env.REACT_APP_OFFERS_URL}/offers/demo/get/quote`, data)
      .then((res) => {
        console.log("response", res);
        toast.success(res.data?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: "toast-success",
        });
        setTimeout(() => {
          navigateToHomePage("/");
        }, 4000);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: "toast-error",
        });
      });
  };

  const formRef = useRef(null);
  useEffect(() => {
    formRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);
  return (
    <>
      <ToastContainer />
      {/* Header */}
      <div
        className={`relative w-full  bg-[url( ${digitalOceanURL}/ContactUsPageAssest/header-bg.svg)]  bg-cover bg-no-repeat h-[600px] header-container`}
      >
        <div className="header-content m-auto max-w-4xl absolute inset-0  flex flex-col justify-center items-center text-center">
          <h1 className="text-[30px] md:text-[54px] lg:text-[54px] font-medium text-white ">
            Get in Touch with Sales
          </h1>
        </div>
      </div>
      {/* ---------------------------------------------------- */}
      <div className="relative  z-10 ">
        <div className="max-w-6xl relative  mx-auto ">
          <div
            ref={formRef}
            className="flex flex-col mx-4  lg:mx-0 md:mx-4 items-center justify-center min-h-screen relative  -top-20 md:-top-36 lg:-top-36 left-0 right-0 "
          >
            {/* Form Container */}
            <div className="w-full max-w-4xl  lg:p-8 md:p-8 p-6 bg-white shadow-lg rounded-[24px]">
              {/* Heading */}
              <h2 className="text-5xl text-center text-[#3A3C3D] font-semibold mb-4 mt-8">
                Connect With Sales
              </h2>
              {/* Subheading */}
              <p className="text-center md:w-2/3 lg:w-2/3 mx-auto font-normal text-[#4D4F4F] mb-12">
                Our experts are ready to help you find the best solution for
                your business.
              </p>

              {/* Form */}
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 ">
                {/* Two Input Fields in One Row */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="flex flex-col">
                    <label
                      htmlFor="first-name"
                      className="text-[#797D82] text-base font-normal mb-1"
                    >
                      First Name
                    </label>
                    <input
                      {...register("first_name", {
                        required: "First name is required",
                      })}
                      type="text"
                      id="first-name"
                      className="w-full p-2 border border-[#C8C9C9] rounded-lg focus:outline-none focus:border-[#1571B7] focus:shadow-sm focus:shadow-[#1571B7]"
                    />
                    {errors.first_name && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.first_name.message}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="last-name"
                      className="text-[#797D82] text-base font-normal mb-1"
                    >
                      Last Name
                    </label>
                    <input
                      {...register("last_name", {
                        required: "Last name is required",
                      })}
                      type="text"
                      id="last-name"
                      className="w-full p-2 border border-[#C8C9C9] rounded-lg focus:outline-none focus:border-[#1571B7] focus:shadow-sm focus:shadow-[#1571B7]"
                    />
                    {errors.last_name && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.last_name.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* Two Input Fields in One Row */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="flex flex-col">
                    <label
                      htmlFor="email"
                      className="text-[#797D82] text-base font-normal mb-1"
                    >
                      Company Email
                    </label>
                    <input
                      {...register("company_email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: "Invalid email address",
                        },
                      })}
                      type="email"
                      id="email"
                      className="w-full p-2 border border-[#C8C9C9] rounded-lg focus:outline-none focus:border-[#1571B7] focus:shadow-sm focus:shadow-[#1571B7]"
                    />
                    {errors.company_email && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.company_email.message}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="company-name"
                      className="text-[#797D82] text-base font-normal mb-1"
                    >
                      Company Name
                    </label>
                    <input
                      {...register("company_name", {
                        required: "Company name is required",
                      })}
                      type="text"
                      id="company-name"
                      className="w-full p-2 border border-[#C8C9C9] rounded-lg focus:outline-none focus:border-[#1571B7] focus:shadow-sm focus:shadow-[#1571B7]"
                    />
                    {errors.company_name && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.company_name.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* Input for Phone Number with Dropdown for Dialing Code */}
                <div className="flex flex-col">
                  <label
                    htmlFor="phone-number"
                    className="text-[#797D82] text-base font-normal mb-1"
                  >
                    Mobile No.
                    <p className="text-[#9C9EA2] text-sm my-1 font-normal">
                      include dialing code eg. +91 for India
                    </p>
                  </label>
                  <div className="flex border border-[#C8C9C9] rounded-lg focus-within:border-[#1571B7] focus-within:shadow-sm focus-within:shadow-[#1571B7]">
                    <select
                      //   {...register("dialing_code", {
                      //     required: "Dialing code is required",
                      //   })}
                      id="dialing-code"
                      className="p-2 border-r-0 appearance-none bg-transparent pr-8 focus:outline-none"
                      style={{
                        backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="%23797D82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>')`,
                        backgroundPosition: "right 0.4rem center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "1.5rem",
                        borderRadius: "0.8rem",
                      }}
                    >
                      <option value="+91">+91</option>
                      <option value="+44">+44</option>
                      <option value="+61">+61</option>
                      <option value="+81">+81</option>
                    </select>
                    <span className="w-[1px] my-2 bg-[#797D82]"></span>
                    <input
                      {...register("mobile_number", {
                        required: "Phone number is required",
                        pattern: {
                          value: /^[0-9]{9,15}$/,
                          message: "Invalid phone number",
                        },
                      })}
                      type="tel"
                      id="phone-number"
                      className="w-full p-2 border-none rounded-r-lg focus:outline-none focus:ring-0"
                      inputMode="numeric"
                      maxLength={10}
                    />
                  </div>
                  {(errors.dialing_code || errors.mobile_number) && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.dialing_code?.message ||
                        errors.mobile_number?.message}
                    </p>
                  )}
                </div>

                {/* Three Select Fields */}
                <div className="grid grid-cols-1 gap-4">
                  {/* <div className="flex flex-col">
                    <label
                      htmlFor="hiring-forecast"
                      className="text-[#797D82] text-base font-normal mb-1"
                    >
                      How many hires are you planning to make in the next 12
                      months?
                    </label>
                    <div className="relative focus-within:border-[#1571B7] focus-within:shadow-sm focus-within:shadow-[#1571B7] border border-gray-300 rounded-lg">
                      <select
                        {...register("hiring_forecast", {
                          required: "Please select an option",
                        })}
                        id="hiring-forecast"
                        className="w-full p-2 border-none rounded-lg focus:outline-none appearance-none pr-8"
                        style={{
                          backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="%23797D82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>')`,
                          backgroundPosition: "right 1rem center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "1.5rem",
                        }}
                      >
                        <option value="">Please Select</option>
                        <option value="1-30">1-30</option>
                        <option value="31-200">31-200</option>
                        <option value="More than 200">More than 200</option>
                      </select>
                    </div>
                    {errors.hiring_forecast && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.hiring_forecast.message}
                      </p>
                    )}
                  </div> */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="employee-count"
                      className="text-[#797D82] text-base font-normal mb-1"
                    >
                      Expected checks per year
                    </label>
                    <div className="relative focus-within:border-[#1571B7] focus-within:shadow-sm focus-within:shadow-[#1571B7] border border-gray-300 rounded-lg">
                      <select
                        {...register("checks_per_year", {
                          required: "Please select an option",
                        })}
                        id="checks-per-year"
                        className="w-full p-2 border-none rounded-lg focus:outline-none appearance-none pr-8"
                        style={{
                          backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="%23797D82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>')`,
                          backgroundPosition: "right 1rem center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "1.5rem",
                        }}
                      >
                        <option value="">Select Option</option>
                        <option value="1-10">1-10</option>
                        <option value="11-25">11-25</option>
                        <option value="26-50">26-50</option>
                        <option value="51-299">51-299</option>
                        <option value="300-1000">300-1000</option>
                        <option value="1000+">1000+</option>
                      </select>
                    </div>
                    {errors.checks_per_year && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.checks_per_year?.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* Submit Button */}
                <div className="text-center">
                  <button
                    type="submit"
                    className="self-center book_demo_btn home_button mb-14 px-6 py-3.5 mt-4 ml-2.5 max-w-full text-base font-medium text-white shadow-sm rounded-[61px] w-[182px] max-md:px-5 max-md:mt-10"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactWithSales;
