import React, { useState, useEffect } from "react";
import "./bgv_timer.style.css";
import BoxBgImage from "./boxes.png";
const BgvTimerComponent = () => {
  // Base data
  const TOTAL_BGV_6_YEARS = 2200000; // 22 lakh in 6 years (base total)
  const LAST_MONTH_BGV = 30000; // Static value for last month
  const MONTH_BGV_BASE = 200; // Base for this month
  const DAY_BGV = 1;
  const HOURLY_BGV = 42;

  const [thisMonthBGV, setThisMonthBGV] = useState(MONTH_BGV_BASE);
  const [totalBGV, setTotalBGV] = useState(TOTAL_BGV_6_YEARS);

  useEffect(() => {
    // Calculate days in current month up to today (April 9, 2025)
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const daysPassed =
      Math.floor((currentDate - startOfMonth) / (1000 * 60 * 60 * 24)) + 1; // +1 to include today

    // Calculate hours passed today
    const hoursPassed = currentDate.getHours();

    const initialMonthBGV =
      MONTH_BGV_BASE + daysPassed * DAY_BGV + hoursPassed * HOURLY_BGV;
    setThisMonthBGV(initialMonthBGV);

    //  total with current month's bgv
    const initialTotal = TOTAL_BGV_6_YEARS + initialMonthBGV;
    setTotalBGV(initialTotal);

    // this update every hour
    const interval = setInterval(() => {
      setThisMonthBGV((prev) => prev + HOURLY_BGV);
      setTotalBGV((prev) => prev + HOURLY_BGV);
    }, 3600000); // Updates every hour

    return () => clearInterval(interval);
  }, []);

  // this add comma to digits
  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className="bg-blue-500 mx-2 counter-container flex items-center justify-center p-6 rounded-[24px] mt-5">
      <div className="lg:py-16 w-full max-w-full lg:mx-4   text-white relative overflow-hidden">
        <div className="relative z-10 flex flex-col md:gap-6 md:flex-row justify-between">
          {/* Left Side - Text */}
          <div className="md:w-1/2  mb-6 md:mb-0 flex justify-start  items-center">
            <div className="p-6">
              <h2 className="text-6xl font-normal">Real-Time BGV Insights</h2>
              <p className="text-base font-normal mt-3">
                We're Scaling Faster Than Ever — Powering Trust And Safety{" "}
                {/* <br /> */}
                With Every Background Verification Done.
              </p>
            </div>
          </div>

          {/* Right Side - Data Boxes */}
          <div className="md:w-1/2 ">
            <div className="flex justify-center mb-3">
              <p className="bg-white text-xs font-medium text-[#1179C2] px-4 py-2 rounded-[2px] transition duration-200">
                Live Update
              </p>
            </div>

            <div className="md:grid grid-cols-1 md:grid-cols-2 gap-2">
              {/* First Row - Two Boxes */}
              <div className="bg-white  bg-opacity-20 p-4 rounded-lg text-center mb-2 lg:mb-0 md:mb-0">
                <p className="text-xs font-medium">Last Month :</p>
                <p className="text-4xl font-semibold">
                  {/* {formatNumber(MONTH_BGV_BASE)} */}
                  {formatNumber(LAST_MONTH_BGV)}
                </p>
              </div>
              <div className="bg-white bg-opacity-20 p-4 rounded-lg text-center mb-2 lg:mb-0 md:mb-0">
                <p className="text-xs font-medium">This Month :</p>
                <p className="text-4xl font-semibold">
                  {formatNumber(thisMonthBGV)}
                </p>
              </div>
              {/* Third Box - Full Width Below */}
              <div className="bg-white bg-opacity-20 mb-2 lg:mb-0 md:mb-0 p-4 rounded-lg text-center flex flex-col items-center justify-center col-span-2">
                <p className="text-xs font-medium">Total :</p>
                <p className="text-4xl font-semibold">
                  {formatNumber(totalBGV)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BgvTimerComponent;
