export const DemoFormValidation = (request_body) => {
  // Check if the request body is empty
  if (!request_body || Object.keys(request_body).length === 0) {
    return "Missing all required parameters";
  }

  // Define the required fields and their validation functions
  const requiredFields = {
    first_name: isValidFirstName,
    last_name: isValidLastName,
    company_email: isValidEmail,
    company_name: isValidCompanyName,
    mobile_number: isValidMobileNumber,
    hiring_forecast: isValidHiringForecast,
    number_of_employees: isValidNumberOfEmployees,
    captcha: (value) => value && value.trim() !== "", // Basic captcha check
  };

  // Check for missing or invalid fields
  for (const [field, validateFn] of Object.entries(requiredFields)) {
    const value = request_body[field];
    if (value === undefined || value === null || value === "") {
      return `Field [${field}] is missing or empty.`;
    }
    if (typeof validateFn === "function" && !validateFn(value)) {
      return `Field [${field}] is invalid.`;
    }
  }

  // Check if additional unnecessary fields exist
  const allowedKeys = Object.keys(requiredFields).concat("purpose_of_contact");
  const extraKeys = Object.keys(request_body).filter(
    (key) => !allowedKeys.includes(key)
  );

  if (extraKeys.length > 0) {
    return `Unexpected fields: [${extraKeys.join(", ")}] are not allowed.`;
  }

  // All validations passed
  return null;
};

let isrequest_bodyInvalid = false;

export function checkingKeys(request_body, data_obj) {
  // console.log(request_body,data_obj)
  for (let key in data_obj) {
    if (!request_body.hasOwnProperty(key)) {
      isrequest_bodyInvalid = true;
      // console.log("Check Key" + key);
      //missing key
      return "Missing required request parameter: [" + key + "]";
    }
  }
}

export function checkFields(request_body) {
  for (const key in request_body) {
    // Skip prototype properties or unexpected keys
    if (
      !request_body.hasOwnProperty(key) ||
      key === null ||
      key === undefined
    ) {
      // console.error("Invalid key encountered:", key);
      continue; // Skip this iteration
    }

    // console.log("Validating key:", key, "Value:", request_body[key]);

    // Check for missing or null/undefined values
    if (
      request_body[key] === null ||
      request_body[key] === undefined ||
      request_body[key] === ""
    ) {
      // console.error(`Field [${key}] is missing or has a value of undefined/null`);
      return key; // Return the key with the issue
    }

    // Add specific validation for each field
    if (key === "first_name" && !isValidFirstName(request_body[key])) {
      return "first_name_invalid"; // Custom error for invalid first name
    }

    if (key === "last_name" && !isValidLastName(request_body[key])) {
      return "last_name_invalid"; // Custom error for invalid last name
    }

    if (key === "company_email" && !isValidEmail(request_body[key])) {
      return "company_email_invalid"; // Custom error for invalid email
    }

    if (key === "mobile_number" && !isValidMobileNumber(request_body[key])) {
      return "mobile_number_invalid"; // Custom error for invalid mobile number
    }

    if (key === "company_name" && !isValidCompanyName(request_body[key])) {
      return "company_name_invalid"; // Custom error for invalid company name
    }

    if (
      key === "hiring_forecast" &&
      !isValidHiringForecast(request_body[key])
    ) {
      return "hiring_forecast_invalid"; // Custom error for invalid hiring forecast
    }

    if (
      key === "number_of_employees" &&
      !isValidNumberOfEmployees(request_body[key])
    ) {
      return "number_of_employees_invalid"; // Custom error for invalid employees count
    }

    if (key === "captcha" && request_body[key] === "") {
      // console.error("Captcha is invalid or empty");
      return "captcha_invalid"; // Custom error for invalid captcha
    }
  }

  return null; // No errors found
}

// email validation function
export function isValidEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

//  mobile number validation
export function isValidMobileNumber(mobileNumber) {
  const regex = /^[0-9]{10}$/;
  return regex.test(mobileNumber);
}

// first name validation
export function isValidFirstName(firstName) {
  const regex = /^[a-zA-Z]+$/;
  return regex.test(firstName);
}

// last name validation
export function isValidLastName(lastName) {
  const regex = /^[a-zA-Z]+$/;
  return regex.test(lastName);
}

// company name validation
export function isValidCompanyName(companyName) {
  const regex = /^[a-zA-Z0-9 &.]+$/;
  return regex.test(companyName);
}

// hiring forecast validation
export function isValidHiringForecast(hiringForecast) {
  const options = ["1-30", "31-200", "More than 200"];
  return options.includes(hiringForecast);
}

//select number of employees
export function isValidNumberOfEmployees(numberOfEmployees) {
  const options = ["Less than 50", "50-499", "More than 500"];
  return options.includes(numberOfEmployees);
}

export const GetQuoteFormValidation = (request_body) => {};
