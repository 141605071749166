import { useEffect, useState } from "react"; // Import an icon for demonstration, replace with your own icons
import { Link, useLocation, useNavigate } from "react-router-dom";
import { VscArrowRight } from "react-icons/vsc";
import LazyImage from "../../LazyImg/LazyImage";
import useWidth from "../../Hooks/useWidth";
// import packageInfo from "../../../package.json";
const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const HirestarLogo = `${digitalOceanURL}/HeaderAssets/hireStar.svg`;
const healthIcon = `${digitalOceanURL}/HeaderAssets/healthIcon.svg`;
const bankingIcon = `${digitalOceanURL}/HeaderAssets/banking&fin.svg`;
const educationIcon = `${digitalOceanURL}/HeaderAssets/education.svg`;
const gameIcon = `${digitalOceanURL}/HeaderAssets/gameIcon.svg`;
const manufactureIcon = `${digitalOceanURL}/HeaderAssets/manufacture.svg`;
const itsIcon = `${digitalOceanURL}/HeaderAssets/its.svg`;
const travelIcon = `${digitalOceanURL}/HeaderAssets/travel.svg`;
const ecommerceIcon = `${digitalOceanURL}/HeaderAssets/ecommerce.svg`;
const fmcgIcon = `${digitalOceanURL}/HeaderAssets/fmcg.svg`;
const gigworkerIcon = `${digitalOceanURL}/HeaderAssets/gigworker.svg`;
const logisticsIcon = `${digitalOceanURL}/HeaderAssets/logistics.svg`;
const hoverIcon = `${digitalOceanURL}/HeaderAssets/hoverIcon.svg`;

export const navItems = [
  {
    name: "Industries",
    children: [
      {
        name: "Banking & Financial Services",
        href: "/industries/banking",
        icon: bankingIcon,
      },
      {
        name: "Healthcare and Pharma",
        href: "/industries/healthcare",
        icon: healthIcon,
      },
      {
        name: "Online Gaming",
        href: "/industries/online-gaming",
        icon: gameIcon,
      },
      { name: "Education", href: "/industries/education", icon: educationIcon },
      {
        name: "Manufacturing",
        href: "/industries/manufacturing",
        icon: manufactureIcon,
      },
      { name: "IT/ITES", href: "/industries/it-ites", icon: itsIcon },
      {
        name: "Travel and Hospitality",
        href: "/industries/travel",
        icon: travelIcon,
      },
      {
        name: "Gig workers",
        href: "/industries/gig-workers",
        icon: gigworkerIcon,
      },
      { name: "Logistics", href: "/industries/logistics", icon: logisticsIcon },
      {
        name: "E-commerce and Retail",
        href: "/industries/ecommerce",
        icon: ecommerceIcon,
      },
      { name: "FMCG/FMCD", href: "/industries/fmcg", icon: fmcgIcon },
      {
        name: "Explore All",
        href: "/industries",
        class: "explore_all_btn",
      },
    ],
  },
  {
    name: "Government APIs",
    children: [
      {
        name: "Identity Verification",
        href: "/govt-api",
      },
      { name: "Aadhaar Verification", href: "/govt-api" },
      {
        name: "Aadhaar Via Digilocker",
        href: "/govt-api",
      },
      {
        name: "Driving License Verification",
        href: "/govt-api",
      },
      {
        name: "PAN Card Verification",
        href: "/govt-api",
      },
      { name: "PAN Aadhaar Seeding", href: "/govt-api" },
      {
        name: "Passport Verification",
        href: "/govt-api",
      },
      {
        name: "Voter ID Verification",
        href: "/govt-api",
      },
      { name: "Aadhaar OCR", href: "/govt-api" },
      { name: "Aadhaar Masking", href: "/govt-api" },
      { name: "Aadhaar Paperless", href: "/govt-api" },
      {
        name: "Aadhaar Offline Verification",
        href: "/govt-api",
      },
      { name: "PAN Name DoB", href: "/govt-api" },
      { name: "Utilities", href: "/govt-api" },
      { name: "Electricity Bill API", href: "/govt-api" },
      { name: "RC Verification", href: "/govt-api" },
      {
        name: "Stolen Vehicle Verification",
        href: "/govt-api",
      },
      { name: "Email Verification", href: "/govt-api" },
      { name: "Tenant Registration", href: "/govt-api" },
      {
        name: "Entity/Business Level",
        href: "/govt-api",
      },
      { name: "DIN", href: "/govt-api" },
      { name: "Udyog Aadhaar", href: "/govt-api" },
      { name: "TIN Search", href: "/govt-api" },
      { name: "GSTIN", href: "/govt-api" },
      {
        name: "Udyog Aadhaar via Phone",
        href: "/govt-api",
      },
      { name: "Employment", href: "/govt-api" },
      { name: "UAN Verification", href: "/govt-api" },
      { name: "EPFO Verification", href: "/govt-api" },
      { name: "CV Validation", href: "/govt-api" },
      {
        name: "Dual Employment Check",
        href: "/govt-api",
      },
      {
        name: "Employment Default Check",
        href: "/govt-api",
      },
      { name: "Banking & Payments", href: "/govt-api" },
      {
        name: "Bank Statement Analysis",
        href: "/govt-api",
      },
      {
        name: "UPI Handle Verification",
        href: "/govt-api",
      },
      {
        name: "Experian Credit Report",
        href: "/govt-api",
      },
      { name: "Address Verification", href: "/govt-api" },
      { name: "Fraud Detection", href: "/govt-api" },
      {
        name: "Face API Verification",
        href: "/govt-api",
      },
      { name: "Liveness Check", href: "/govt-api" },
      { name: "Court Check", href: "/govt-api" },
      {
        name: "Defaulting Director Check",
        href: "/govt-api",
      },
      {
        name: "Global Sanctions Check",
        href: "/govt-api",
      },
      { name: "Professional", href: "/govt-api" },
      {
        name: "Insurance Agent Verification",
        href: "/govt-api",
      },
      { name: "Nurses Verification", href: "/govt-api" },
      {
        name: "Medical Document Validation",
        href: "/govt-api",
      },
      {
        name: "Medical Opinion Validation",
        href: "/govt-api",
      },
      { name: "ICSI Verification", href: "/govt-api" },
      {
        name: "Explore All",
        href: "/govt-api",
        class: "explore_all_btn", // Add class to make it a button
      },
    ],
  },
  {
    name: "Products",
    children: [
      { name: "HR Portal", href: "/hr-portal" },
      { name: "Self Verification Portal", href: "/self-verification" },
      { name: "Grievance Portal", href: "/grievance-portal" },
      { name: "Offer Portal", href: "/offer-portal" },
    ],
  },
  {
    name: "Company",
    children: [
      { name: "About us", href: "/about-us" },
      { name: "Blogs", href: "/blogs" },
      { name: "Contact us", href: "/contact-us" },
    ],
  },
];
export default function Navbar() {
  const [lastScrollY, setLastScrollY] = useState(0); // To track the last Y scroll position
  const [isScrollingUp, setIsScrollingUp] = useState(true); // Track whether user is scrolling up
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar state
  const [openDropdown, setOpenDropdown] = useState(null); // Mobile dropdown state
  const width = useWidth();
  const [dropdownVisible, setDropdownVisible] = useState(null); // State to track which dropdown is open
  const [isReloading, setIsReloading] = useState(false);
  const location = useLocation();

  // Scroll detection logic for header visibility
  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    // Determine scroll direction
    if (currentScrollY > lastScrollY && currentScrollY > 100) {
      // User is scrolling down, hide header
      setIsScrollingUp(false);
    } else {
      // User is scrolling up, show header
      setIsScrollingUp(true);
    }

    // Update the last scroll position
    setLastScrollY(currentScrollY);
  };
  // console.log("packageInfo", packageInfo);
  // Add event listener for scroll detection
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);
  const navigate = useNavigate();
  const handleChildClick = (href, name) => {
    if (href) {
      // If user is already on the current page, reload it
      navigate(href, { state: { name } });
      setIsReloading(true);
      // navigate(href, { state: { name } });
      // setTimeout(() => {
      //   setIsReloading(false);
      //   window.location.reload(); // Reload the page after a brief delay
      // }, 100);
      setIsReloading(false);
      setDropdownVisible(null);
      // window.location.reload();
    }
    // else {
    //   // Hide the dropdown when a child link is clicked
    //   setDropdownVisible(null);
    // }
  };

  // Sidebar toggle logic
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setOpenDropdown(null); // Close any open dropdown when the sidebar opens
  };

  const toggleDropdown = (index) => {
    if (openDropdown === index) {
      setOpenDropdown(null); // Close if it's already open
    } else {
      setOpenDropdown(index); // Open the clicked one
    }
  };

  const handleChildClick1 = (href, name) => {
    toggleSidebar(); // Close the sidebar
    if (href) {
      // If already on the same page, reload it
      navigate(href, { state: { name } });
      setOpenDropdown(null);
      // setTimeout(() => {
      //   // window.location.reload(); // Reload the page after closing the sidebar
      // }, 50);
    }
  };

  const navigateToHome = () => {
    navigate("/");
    setOpenDropdown(null); // Close any open dropdown when navigating to the home page
  };

  useEffect(() => {
    if (width > 768) {
      setIsSidebarOpen(false);
    }
  }, [width]);

  // console.log((isSidebarOpen && width) < 768)
  return (
    <>
      <header
        className={`bg-white fixed left-0 right-0 z-50 rounded-[60px] border w-11/12 mx-auto shadow-md 
    transition-transform duration-300 ease-in-out
    ${
      isScrollingUp
        ? "top-2 transform translate-y-0"
        : "top-0 transform -translate-y-full"
    }`}
      >
        <div className="mx-auto pr-5">
          <div className="flex items-center justify-between h-16">
            {/* HireStar Logo */}
            <div className="flex-shrink-0 mb-1">
              <img
                onClick={navigateToHome}
                className="nav-logo object-contain cursor-pointer"
                title="Hirestar Logo"
                src={HirestarLogo}
                alt="navbar hirestar logo"
                width="150"
                height="75"
              />

              {/* </Link> */}
            </div>

            {/* Desktop Navigation - hidden on smaller screens */}
            <nav className="hidden lg:flex items-center space-x-5 nav">
              {navItems.map((item, index) => (
                <div
                  key={item.name}
                  className="relative group "
                  onMouseEnter={() => setDropdownVisible(index)} // Show the dropdown on hover
                  onMouseLeave={() => setDropdownVisible(null)} // Hide the dropdown on mouse leave
                >
                  <Link
                    to={item.href}
                    className={`hover:text-[#1B8ACA] nav-link  px-2 py-2 rounded-md text-base font-normal flex items-center transition-all duration-300 ease-in-out ${
                      location.pathname === item?.href
                        ? "text-[#1B8ACA]"
                        : "text-black"
                    }`}
                    data-name={item.name}
                  >
                    {item.name}
                    {item.children && (
                      <span
                        className={`ml-1 flex transform transition-transform duration-300 group-hover:rotate-180`}
                      >
                        <img
                          loading="lazy"
                          src={hoverIcon}
                          title="Hover Icon"
                          alt={`${item.name} blue icon`}
                        />
                      </span>
                    )}
                  </Link>

                  {/* Dropdown Menu */}
                  {item.children && dropdownVisible === index && (
                    <div
                      className={`absolute z-10 top-11 ${
                        item.name === "Government APIs" && "lg:-right-[26.5rem]"
                      } w-auto rounded-3xl ${
                        item.name === "Company" && " w-[12rem]"
                      } shadow-lg  bg-white ring-1 ring-black ring-opacity-5 p-3 transition-all duration-500 ease-in-out transform origin-top `}
                    >
                      <div className="relative">
                        <div className="text-[#101010] dropdown mb-4 ml-4 font-normal text-[22px]">
                          {item.name}
                        </div>
                        <hr className="border-[1.2px] border-[#E4E9EA]" />

                        {/* Displaying Industries Dropdown in Groups of 6 */}
                        <div
                          data-parent={item.name}
                          className="flex space-x-8 pb-8 relative "
                        >
                          {item.children
                            .reduce((acc, subItem, index) => {
                              // Group children by 4 or 10 items per column for Industries
                              const groupIndex = Math.floor(
                                index / (item.name === "Industries" ? 4 : 10)
                              );
                              if (!acc[groupIndex]) {
                                acc[groupIndex] = [];
                              }
                              acc[groupIndex].push(subItem);
                              return acc;
                            }, [])
                            .map((group, groupIndex, arr) => (
                              <div
                                key={groupIndex}
                                className="flex flex-col space-y-2 relative"
                              >
                                {group.map((subItem) => (
                                  <p
                                    key={subItem.name}
                                    // to={{ pathname: subItem.href, state: { someProp: subItem.name } }}
                                    // prop={subItem.name}
                                    className={`font-normal cursor-pointer rounded-[4px] mt-4 text-sm text-[#101010] hover:bg-[#EDF7FF] flex items-center whitespace-nowrap px-5 py-1 ${
                                      subItem.name === "Explore All"
                                        ? "explore_all_btn justify-center"
                                        : ""
                                    }`} // Add extra class if it's "Explore All"
                                    onClick={() =>
                                      handleChildClick(
                                        subItem.href,
                                        subItem.name
                                      )
                                    } // Hide or reload dropdown on click
                                  >
                                    {subItem.icon && (
                                      <img
                                        loading="lazy"
                                        src={subItem.icon}
                                        className="mr-2 text-gray-600 w-4 h-4"
                                        alt={`${subItem.name} icon `}
                                      />
                                    )}
                                    {subItem.name === "Explore All" ? (
                                      <span className="flex items-center">
                                        {" "}
                                        {/* Center both text and arrow */}
                                        {subItem.name}
                                        <VscArrowRight className="ml-2 text-white font-bolder" />
                                      </span>
                                    ) : (
                                      subItem.name
                                    )}
                                  </p>
                                ))}

                                {/* Vertical Divider Between Columns After the First Column */}
                                {groupIndex < arr.length - 1 && (
                                  <div className="absolute -top-2 right-[-16px] w-[1.2px] bg-[#E4E9EA] h-full"></div>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <Link
                to="/quote-request"
                className="demo_btm shadow-sm text-white px-4 py-2.5 rounded-full text-sm font-medium"
              >
                Get a Quote
              </Link>
            </nav>

            {/* Mobile menu button - visible on smaller screens */}
            <div className="lg:hidden block">
              <button
                onClick={toggleSidebar}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
              >
                {/* <span className="sr-only">Open main menu</span> */}
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Sidebar for mobile */}
      {(isSidebarOpen && width) <= 768 && (
        <div
          className={`fixed inset-y-0 right-0 max-w-xs w-full bg-white shadow-xl z-50 overflow-y-auto transition-transform duration-300 ease-in-out transform ${
            isSidebarOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="p-6">
            <button
              aria-label="Toggle sidebar"
              onClick={toggleSidebar}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            {/* Mobile Navigation */}
            <nav className="mt-8 mb-12">
              {navItems.map((item, index) => (
                <div key={item.name} className="mb-4">
                  <p
                    // to={item.href}
                    onClick={() => toggleDropdown(index)}
                    className="text-black hover:text-gray-900 px-3 py-2 rounded-md text-base font-normal w-full text-left flex items-center "
                  >
                    {item.name}
                    {item.children && (
                      <span
                        className={`ml-1 transform transition-transform duration-300 ${
                          openDropdown === index ? "rotate-180" : "rotate-0"
                        }`}
                      >
                        &#9662;
                      </span>
                    )}
                  </p>

                  {/* Dropdown Menu for Mobile */}
                  {item.children && openDropdown === index && (
                    <div className="pl-4 mt-2 text-start">
                      {item.children.map((subItem) => (
                        <p
                          key={subItem.name}
                          // to={subItem.href}
                          className={`flex px-3 py-2 rounded-md text-base font-normal text-[#101010] hover:bg-[#EDF7FF] ${
                            subItem.name === "Explore All"
                              ? "explore_all_btn justify-center"
                              : ""
                          }`} // Add extra class if it's "Explore All"
                          onClick={() =>
                            handleChildClick1(subItem.href, subItem.name)
                          } // Handle child click
                        >
                          {subItem.icon && (
                            <img
                              loading="lazy"
                              src={subItem.icon}
                              className="mr-2 text-gray-600 w-4 h-4"
                              alt={`${subItem.name} icon side bar`}
                            />
                          )}
                          {subItem.name === "Explore All" ? (
                            <span className="flex items-center justify-center">
                              {" "}
                              {/* Center both text and arrow */}
                              {subItem.name}
                              <VscArrowRight className="ml-2 text-white font-bold" />
                            </span>
                          ) : (
                            subItem.name
                          )}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </nav>

            <Link
              to="/quote-request"
              className="mt-6 w-full text-white px-6 py-4 shadow-sm text-sm font-medium hover:bg-[#1e84c0] 
            bg-gradient-to-t from-[#0D6EB5] to-[#2AABE2] rounded-full"
              onClick={toggleSidebar} // Close sidebar when clicking "Book a Demo"
            >
              Get a Quote
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

// -----------------------------------------------
// Ensure Link is imported
