import React, { Suspense, lazy } from "react";
import LazyComponent from "../../LazyComponent/LazyComponent";
import LazyLoad from "react-lazy-load";

// Lazy load the components
// const HomePageHeroSection = lazy(() => import('./HomePageHeroSection/HomePageHeroSection'));
// const AboutSection = lazy(() => import('./AboutSection/AboutSection'));
// const IndustrySection = lazy(() => import('./IndustrySection/IndustrySection'));
// const OurClients = lazy(() => import('./OurClientSection/OurClients'));
// const OurServiceSection = lazy(() => import('./OurServiceSection/OurServiceSection'));
// const WhyHirestarSection = lazy(() => import('./WhyHirestarSection/WhyHirestarSection'));
// const TestimonialSection = lazy(() => import('./TestimonialSection/TestimonialSection'));
// const FaqComponent = lazy(() => import('../../Components/Footer/FaqComponent/FaqComponent'));
// const HelpCard = lazy(() => import('../../Components/Footer/HelpCenterCard/HelpCard'));
// ------------------------- Lazy Load Components --------------------------------------------------//
import HomePageHeroSection from "./HomePageHeroSection/HomePageHeroSection";
import AboutSection from "./AboutSection/AboutSection";
import IndustrySection from "./IndustrySection/IndustrySection";
import OurClients from "../../Components/OurClientSection/OurClients";
import OurServiceSection from "./OurServiceSection/OurServiceSection";
import TestimonialSection from "./TestimonialSection/TestimonialSection";
import WhyHirestarSection from "./WhyHirestarSection/WhyHirestarSection";
import FaqComponent from "../../Components/Footer/FaqComponent/FaqComponent";
import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";
import BgvTimerComponent from "./BgvTimerComponent/BgvTimerComponent";
const HomePage = () => {
  return (
    <div>
      <HomePageHeroSection />
      <BgvTimerComponent />
      <div className="component_spacing">
        <AboutSection />
      </div>
      <IndustrySection />
      <OurClients />
      <OurServiceSection />
      <WhyHirestarSection />
      <TestimonialSection />
      <FaqComponent />
      <div className="relative top-28  left-0 right-0 help_card">
        <HelpCard />
      </div>
      {/* --------------------------------------------------------- */}
      {/* <Suspense fallback={<></>}>
      <HomePageHeroSection />
        <div className="component_spacing">
          <AboutSection />
        </div>
        <IndustrySection />
        <OurClients />
        <OurServiceSection />
        <WhyHirestarSection />
        <TestimonialSection />
        <FaqComponent />
        <div className="relative top-28 left-0 right-0 help_card">
          <HelpCard />
        </div>
      </Suspense> */}
      {/* -------------------------------------------------- */}
      {/* <div> */}
      {/* </div> */}
      {/* <HomePageHeroSection />
      <Suspense fallback={<>loading...</>}>
        <LazyComponent>
          <HomePageHeroSection />
        </LazyComponent>

        <div className="component_spacing">
          <LazyComponent>
            <AboutSection />
          </LazyComponent>
        </div>

        <LazyComponent>
          <IndustrySection />
        </LazyComponent>

        <LazyComponent>
          <OurClients />
        </LazyComponent>

        <LazyComponent>
          <OurServiceSection />
        </LazyComponent>

        <LazyComponent>
          <WhyHirestarSection />
        </LazyComponent>

        <LazyComponent>
          <TestimonialSection />
        </LazyComponent>

        <LazyComponent>
          <FaqComponent />
        </LazyComponent>

        <div className="relative top-28 left-0 right-0 help_card">
          <LazyComponent>
            <HelpCard />
          </LazyComponent>
        </div>
      </Suspense> */}
    </div>
  );
};

export default HomePage;
